<template>
  <div class="luti_warp">
    <!-- <video src="https://sunraytiku.oss-cn-beijing.aliyuncs.com/slczb/videoResource/juniorOne/history/huodong/ddd.mp4"></video> -->
    <!-- 选择考点之后，每个考点可以输入分值 -->
    <el-form :model="form"
             ref="addFormRef"
             :rules="rules"
             label-width="70px">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="科目:"
                        prop="subject_id">
            <el-select v-model="form.subject_id"
                       style="width:100%"
                       @change="changeSubject"
                       :disabled="isActive"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷地区:"
                        label-width="110px"
                        prop="dis_id">
            <el-select v-model="form.dis_id"
                       style="width:100%"
                       clearable
                       filterable
                       @change="getSourceList"
                       :disabled="isActive"
                       placeholder="请选择">
              <el-option v-for="item in disList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年级:"
                        prop="grade_id">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       @change="getSourceList"
                       clearable
                       :disabled="isActive "
                       placeholder="请选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="题目来源:"
                        label-width="110px"
                        prop="source_id">
            <el-select v-model="form.source_id"
                       style="width:100%"
                       filterable
                       :disabled="isActive"
                       placeholder="请选择">
              <el-option v-for="item in paperSourceList"
                         :key="item.source_id"
                         :label="item.source_id+ '-' + item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item label="题目年份:"
                        label-width="110px"
                        prop="questionYear">
            <el-date-picker v-model="form.questionYear"
                            :disabled="isActive"
                            @focus="$forbid"
                            value-format="yyyy"
                            type="year"
                            style="width:100%"
                            placeholder="选择年">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button type="primary"
                     @click="confrim">确定</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="warp">
      <div class="left_tree">
        <div class="add_btn_warp">
          <div style="margin-top:10px"
               @click="showForm">
            试卷备注
          </div>
          <div @click="oneTopic">
            添加独立题目
          </div>
          <br>
          <div @click="addbigTopic">
            添加题头
          </div>
        </div>
        <p style="text-align:center;line-height:25px;"
           v-if="leftTree.length">试卷总分:{{nums}}</p>
        <el-tree :data="leftTree"
                 :props="defaultProps"
                 @node-click="handleNodeClick">
          <span class="custom-tree-node"
                slot-scope="{ node, data }">
            <span>{{ data.question_code }}</span>
            <span class="right_btn"
                  v-if="data.question_score == 0 && data.question_code_head == ''">
              <el-tooltip class="item"
                          effect="dark"
                          content="添加小题"
                          placement="top-start">
                <img src="@/assets/icon-child@2x.png"
                     @click.stop="() => addChildren(node, data)"
                     style="width:16px;height:16px"
                     alt="">
              </el-tooltip>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="right_content"
           v-if="isLuti">
        <div v-if="isActive">
          <formDialog v-if="name"
                      ref="formDialogDialogRef"
                      :parentForm="form"
                      :selects="selects"
                      :name="name" />
          <Independent ref="IndependentRef"
                       v-if="name"
                       :name="name" />
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button v-if="name"
                         @click="checkForm"
                         type="primary">提交</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right_content"
           v-else>
        <proofread ref="proofreadRef"
                   :item="item1" />
      </div>
      <Remark ref="RemarkRef" />
    </div>
  </div>
</template>

<script>
import Independent from './independent.vue'
import formDialog from './formComponent.vue'
import proofread from './info.vue'
import { getGrade } from '@/api/grade.js'
import { getAllPaperDis } from '@/api/dis.js'
import Remark from './remark.vue'
import { getNote } from '@/api/note.js'
export default {
  components: {
    Independent, formDialog, proofread, Remark
  },
  data () {
    return {
      form: {
        subject_id: '',
        source_id: '',
        questionYear: ''
      },
      nums: 0,
      rules: {
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        questionYear: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      name: '',
      subjectList: [],
      defaultProps: {
        children: 'child',
        label: 'question_code'
      },
      selects: [],
      options: [
        { label: '无题头，独立选择' },
        { label: '本题就是一个题头' },
        { label: '本题带有题头' },
      ],
      // 是否确定试卷，当三项选择框确定之后就确定了试卷来源
      isActive: false,
      // 左侧树
      leftTree: [],
      isLuti: true,
      item1: {},
      formIsOver: 0,
      formIsOver1: 0,
      oldForm: {
        questionType: '',
        questionScore: '',
        questionCategory: ''

      },
      disList: [],
      gradeList: [],
      paperSourceList: [],
      capability: []
    }
  },
  watch: {
    name () {
      if (this.name == '') return
      this.$nextTick(() => {
        var code = ''
        var item = this.leftTree[this.leftTree.length - 1]
        this.setOldForm()

        if (item) {
          // 存在子集
          if (item.child && item.child.length) {
            code = item.child[item.child.length - 1].question_code
          } else {
            code = item.question_code
          }
          var number = code.split(',')[1]
          if (number.indexOf('*') != -1) {
            number = number.split('*')[0]
          }
          number = Number(number) + 1
          if (number < 10) {
            number = ',0' + number
          } else {
            number = ',' + number
          }
          if (this.name == '无题头，独立选择') {
            code = code.split(',')[0] + number
          } else if (this.name == '本题就是一个题头') {
            code = code.split(',')[0] + '' + number + "*"
          }
        }

        /*
        本题就是一个题头
        */
        this.$refs.formDialogDialogRef.resetForm(code)
        this.$refs.IndependentRef.refresh()

      })
    }
  },
  mounted () {
    document.getElementsByClassName('view_warp')[0].style.padding = '0'
    document.getElementsByClassName('view_warp')[0].style['padding-top'] = '15px'
    this.getSubject()
    this.initList()
  },
  methods: {
    getSourceList () {
      this.form.source_id = ''
      this.paperSourceList = []
      if (this.form.subject_id == '') return
      this.$http({
        url: '/api/v1/public/source_lst',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          district_id: this.form.dis_id,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.paperSourceList = res.data
      })
    },
    async initList () {
      const { data } = await getGrade()
      this.gradeList = data.list
      const { data: disData } = await getAllPaperDis()
      this.disList = disData
    },
    setOldForm () {
      let form = JSON.parse(JSON.stringify(this.oldForm))

      if (this.name == '本题就是一个题头') {
        form = {
          questionType: '',
          questionScore: 0
        }
      }
      setTimeout(() => {
        this.$refs.formDialogDialogRef.form.questionScore = form.questionScore || 0
        this.$refs.formDialogDialogRef.form.questionType = form.questionType
        if (this.form.subject_id == 3) {
          this.$refs.formDialogDialogRef.form.questionCategory = this.oldForm.questionCategory
        }
      }, 500);
    },
    handleNodeClick (data) {
      this.item1 = data
      this.item1.capability = this.capability
      console.log('this.item1', this.item1)
      this.item1.capability_data = [...new Set(this.item1.capability_data.flat(Infinity))]
      console.log('capability_data',this.item1.capability_data )
      this.isLuti = false
    },
    getCapability () {
      this.$http({
        url: '/api/v1/question/capabilities',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
        }
      }).then(res => {
        this.capability = res.data.list
      })
    },
    getSelectList () {
      this.$http({
        url: '/api/v1/question/choices',
        method: 'POST',
        data: {
          source_id: this.form.source_id,
          noCheckpoint: 0,
          subject_id: this.form.subject_id,
          district_id: this.form.dis_id,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.selects = res.data
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        res.data.subject.splice(0, 1)
        this.subjectList = res.data.subject
        this.form.subject_id = this.subjectList[0].subject_id
        this.getSourceList()
        this.getCapability()
        // this.getSelectList()
      })
    },
    changeSubject () {
      this.getSourceList()
      this.getCapability()
    },
    confrim () {
      this.$refs.addFormRef.validate(val => {
        if (!val) return
        this.isActive = true
        this.getPaperList()
        this.getSelectList()
      })
    },
    addTopic () {
      if (this.name != '') {
        this.$confirm("当前题目未提交，是否添加新题目？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          // 在这里清空form表单里的选项
          this.name = ''
          this.$refs.checkDialogRef.dialogVisible = true
        })
      } else {
        this.$refs.checkDialogRef.dialogVisible = true
      }
    },
    handleClose () {
      this.name = ''
    },
    addChildren (node, data) {
      if (!this.isActive) {
        return this.confrim()
      }
      this.isLuti = true
      this.name = '本题带有题头'
      setTimeout(() => {
        this.$refs.formDialogDialogRef.form.questionCode = this.getCode(data)
        this.$refs.formDialogDialogRef.form.questionCodeHead = data.question_code
      }, 500);
    },
    getCode () {
      let data = this.leftTree[this.leftTree.length - 1]
      var code = ''
      var index = 1
      var number
      var str = ''
      if (data.child && data.child.length) {
        str = data.child[data.child.length - 1].question_code
        number = Number(str.split(',')[1]) + 1
        index = Number(data.child[data.child.length - 1].question_serial_head) + 1
        if (number < 10) {
          number = '0' + number
        }
        code = str.split(',')[0] + ',' + number

      } else {
        if (data.question_code.indexOf('*') == -1) {
          number = Number(data.question_code.split(',')[1]) + 1
          if (number < 10) {
            number = '0' + number
          }
          code = data.question_code.split(',')[0] + ',' + number
        } else {
          code = data.question_code.split('*')[0]
        }
        index = 1
      }

      // 自动赋值序号
      this.$refs.formDialogDialogRef.form.questionSerialHead = index

      return code
    },
    oneTopic () {
      if (!this.isActive) {
        return this.confrim()
      }
      this.isLuti = true
      this.name = '无题头，独立选择'
    },
    addbigTopic () {
      if (!this.isActive) {
        return this.confrim()
      }
      this.isLuti = true
      this.name = '本题就是一个题头'
    },
    checkForm () {
      this.$refs.formDialogDialogRef.validateForm()
    },
    save1 () {
      this.$refs.IndependentRef.validateForm()
    },
    save () {
      let form = this.$refs.formDialogDialogRef.form

      this.oldForm = {
        questionType: form.questionType,
        questionScore: form.questionScore,
        questionCategory: form.questionCategory
      }


      form = Object.assign(form, this.$refs.IndependentRef.form)
      form.questionYear = this.form.questionYear
      form.questionSource = this.form.source_id


      var knowledgeScore = 0
      form.knowledge.forEach(item => {
        knowledgeScore += Number(item.knowledge_score)
      });


      if (knowledgeScore != form.questionScore && knowledgeScore != 0) {
        this.$message.warning('知识点总分不等于题目分数')
        return
      }


      // var chapterScore = 0
      // form.chapters.forEach(item => {
      //   chapterScore += Number(item.chapter_score)
      // });

      // if (chapterScore != form.questionScore) {
      //   this.$message.warning('章节总分不等于题目分数')
      //   return
      // }


      this.$http({
        url: '/api/v1/question/create',
        method: 'POST',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '创建成功!',
          type: 'success'
        });
        this.refresh()
        this.getPaperList()
      })
    },
    refresh () {
      this.$refs.formDialogDialogRef.refresh()
      this.$refs.IndependentRef.refresh()
      this.$nextTick(() => {
        this.name = ''
      })
    },
    getPaperList () {
      this.nums = 0

      this.$http({
        url: '/api/v1/question/tree',
        method: 'get',
        params: {
          paperSource: this.form.source_id,
          paperYear: this.form.questionYear
        }
      }).then(res => {
        this.isActive = true
        this.leftTree = res.data.list
        this.nums = res.data.paper_score
      })
    },
    async showForm () {
      if (!this.isActive) {
        return this.confrim()
      }
      let form = {
        source_id: this.form.source_id,
        year: this.form.questionYear,
      }
      let { data } = await getNote(form)
      console.log(data)
      form = Object.assign(form, data)
      this.$refs.RemarkRef.form = form
      this.$refs.RemarkRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.luti_warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.warp {
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  height: 94%;
  overflow: auto;
  .left_tree {
    padding: 10px;
    width: 180px;
    overflow: auto;
    background: white;
    border-top: 2px solid #f0f2f5;
    div {
      ::-webkit-scrollbar {
        display: none;
      }
    }
    scrollbar-width: none; /* Firefox */
  }
  .right_content {
    padding: 1%;
    padding-bottom: 50px;
    flex: 1;
    margin-top: 1.3%;
    margin-left: 1.3%;
    overflow: auto;
    background: white;
  }
}
.el-tree {
}

.custom-tree-node {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right_btn {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
}
.add_btn_warp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  div {
    margin: 0 30px;
    width: 100px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    border-radius: 2px;
    color: #3f9eff;
    text-align: center;
    border: 1px solid #3f9eff;
    font-weight: bold;
    cursor: pointer;
    &:nth-child(2) {
      margin: 10px 0;
    }
  }
}
</style>